export default {
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username or password',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.username.placeholder': 'Username: admin or user',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password: ant.design',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.Settings.OIDC.emptyPrompt': 'There is no third auth provider, please add one to use this feature.',
  'pages.Settings.OIDC.confirm-delete-title': 'Confirm Delete',
  'pages.Settings.OIDC.confirm-delete-content': 'The related third-auth users will no be able to log in any more. You can delete these users in the users management.',
  'pages.Settings.Strategy.whitelist_sep':'Separated by comma, semicolon, spaces or new line.',
  'pages.Settings.Strategy.whitelist_tip':'Only whitelisted IP can access me.',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip':'The device will not be discovered by other devices in the same LAN.',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip':'Allow direct IP connection to the device.',
  'pages.CustomClient.bidirectional_desc_tip': 'A client from which you can create connections to another device and receive incoming connection requests to your device.',
  'pages.CustomClient.incoming_desc_tip': 'A client that can only receive a connection from another device.',
  'pages.CustomClient.outgoing_desc_tip': 'A client from which you can only create an outgoing connection.',
  'pages.CustomClient.disable_installation_desc_tip': 'Disallow users to install the custom client (Windows-only).',
  'pages.CustomClient.disable_settings_desc_tip': 'Disable user\'s access to the custom client\'s settings.',
  'pages.CustomClient.diable_ab_desc_tip': 'Disable the Address Book feature in the custom client.',
  'pages.CustomClient.diaable_tcp_desc_tip': 'Avoid the Windows Firewall dialog when RustDesk is started.',
  'pages.CustomClient.disable_account_desc_tip': 'Remove the possibility for the user to log in to their account in the RustDesk client.',
  'pages.CustomClient.app_icon_desc_tip': 'Upload a square PNG image with a minimum size of 512x512 pixels for the desired icon for your custom client.',
  'pages.CustomClient.app_name_desc_tip': 'specify a unique name, displayed in the start menu, control panel, etc. for installed RustDesk clients (Windows-only)',
  'pages.CustomClient.logo_desc_tip': 'Upload a PNG image that is shown at the top of the custom client’s Main Window. The size is scaled automatically. We recommend using a PNG with 200x40 pixels.',
  'pages.CustomClient.override_settings_desc_tip': 'Override settings without the possibility to change it back within RustDesk, seperated by newline.',
  'pages.CustomClient.default_settings_desc_tip': 'Change the default settings that RustDesk is using by default, seperated by newline.',
  'pages.welcome.advancedComponent': 'Advanced Component',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.advancedLayout': 'Advanced Layout',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
};
